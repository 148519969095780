<template>
  <div class="w1092">
      <p class="myfix"><span class="rdc">{{userData.memNick}}</span>{{$t('front.mypage.infoEdit')}}<br>{{$t('front.mypage.security')}}</p>
      <div class="myfixwrap">
        <ul class="joinu mb50">
          <li>{{$t('front.common.memId')}}</li>
          <li>{{userData.memId}}</li>
        </ul>
        <ul class="joinu">
          <li>{{$t('front.common.password')}}</li>
          <li><input type="password" placeholder="" v-model="model.memPass"></li>
        </ul>
        <span class="joinutext mb30">{{$t('front.signup.passwordPlaceholder')}}</span>
        <ul class="joinu">
          <li>{{$t('front.mypage.passwordOk')}}</li>
          <li><input type="password" placeholder="" v-model="model.memPassCheck"></li>
        </ul>
        <ul class="joinu">
          <li>{{$t('front.common.cashOutPass')}}</li>
          <li><input type="text" id="cashOutPass"  maxlength="4"  pattern="[0-9]*" v-model="model.cashOutPass" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"  inputmode="numeric"  placeholder="" /></li>
        </ul>
        <span class="joinutext mb30">{{$t('front.signup.cashOutPassPlaceholder')}}</span>
        <ul class="joinu mb50">
          <li>{{$t('front.mypage.ExchangepasswordOk')}}</li>
          <li><input  type="text" id="cashOutPassCheck" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"  maxlength="4"  pattern="[0-9]*" inputmode="numeric"  placeholder="" v-model="model.cashOutPassCheck"></li>
        </ul>
        <div class="joinubottom">
          <ul class="joinu">
            <li>{{$t('front.common.level')}}</li>
            <li>{{$t('front.common.level')}} LV. {{userData.memLevel}}</li>
          </ul>
          <!--ul class="joinu">
            <li>스포츠</li>
            <li>레벨 LV. {{userData.sportsLevel}}</li>
          </ul-->
          <ul class="joinu">
            <li>{{$t('front.gnb.casino')}}</li>
            <li>{{$t('front.common.level')}} LV. {{userData.casinoLevel}}</li>
          </ul>
          <ul class="joinu">
            <li>{{$t('front.gnb.slot')}}</li>
            <li>{{$t('front.common.level')}} LV. {{userData.slotLevel}}</li>
          </ul>
          <!--ul class="joinu">
            <li>미니게임</li>
            <li>레벨 LV. {{userData.mgLevel}}</li>
          </ul-->
        </div>
      </div>
      <div class="boardbtn"><a class="rdsbtn" @click="onUpdate">{{$t('front.board.edit')}}</a></div>

    </div>
</template>

<script>
import { isValidOnlyNumber, isValidPassword } from '@/libs/utils'
import { memberUpdate } from '@/api/member'

export default {
  name: 'MyPageInfo',
  data () {
    return {
      model: {
        memPass: '',
        cashOutPass: '',
        cashOutPassCheck: '',
        memPassCheck: ''
      }
    }
  },
  methods: {
    isValidate () {
      const data = this.model
      for (const key of Object.keys(data)) {
        const value = data[key]

        if (key === 'memPassCheck') {
          if (data[key] === '') {
            this.onAlert('warningart', 'front.member.compareMemPass')
            return false
          }
        }

        if (key === 'memPass') {
          if (data[key] === '') {
            this.onAlert('warningart', 'front.member.emptyMemPass')
            return false
          }

          if (!isValidPassword(value) || value.length > 20) {
            this.onAlert('warningart', 'api.U102')
            return false
          }
        }

        if (key === 'cashOutPassCheck') {
          if (data[key] === '') {
            this.onAlert('warningart', 'front.member.emptyCashOutPass')
            return false
          }
        }

        if (key === 'cashOutPass') {
          if (data[key] === '') {
            this.onAlert('warningart', 'front.member.emptyCashOutPass')
            return false
          }

          if (!isValidOnlyNumber(value) || value.toString().length !== 4) {
            this.onAlert('warningart', 'api.U103')
            return false
          }
        }

        if (data.memPass !== data.memPassCheck) {
          this.onAlert('warningart', 'front.member.compareMemPass')
          return false
        }

        if (data.cashOutPass !== data.cashOutPassCheck) {
          this.onAlert('warningart', 'front.member.compareCashPassCheck')
          return false
        }
      }

      return true
    },
    onUpdate () {
      if (this.isValidate()) {
        const param = {
          memId: this.userData.memId,
          memPass: this.model.memPass,
          cashOutPass: this.model.cashOutPass
        }

        memberUpdate(param).then(response => {
          const result = response.data
          if (result.resultCode === '0') {
            this.onCheck('front.member.completeUpdate')
          } else {
            this.onCheck('api.' + result.resultCode)
          }

          this.model = {
            memPass: '',
            cashOutPass: '',
            cashOutPassCheck: ''
          }
        })
      }
    }
  }
}
</script>
<style scoped>
#cashOutPass, #cashOutPassCheck {
  -webkit-text-security: disc;
}

.myfix {font-size: 18px;line-height: 1.5em;text-align: center;margin: 15px 0 30px;color: #000;}
.myfixwrap {border: 1px dotted #14257a;margin-top: 30px;padding: 50px;border-radius: 10px;}
.joinu {display: flex;gap: 38px;}
.joinu li:first-child {width: 150px;color: #000;font-weight: bold;}
.joinu li:first-child::after {content: '';width: 2px;height: 19px;background: #6b7aa3;position: absolute;right: 0;}
.joinu li {position: relative;display: flex;align-items: center;font-size: 16px;color: #8e8e8e;}
.joinutext {font-size: 14px;color: #6b7aa3;padding: 8px 0 0 150px;text-align: left;display: block;}
.joinu input{padding: 0 0 0 5px;height: 50px;border: 0;width: calc(100% - 5px);font-size: 16px;background: none;color: #000;}
.joinubottom {border-top: 1px solid #14257a;padding: 0 340px;}
.joinubottom .joinu {margin-top: 18px;gap: 0;}
.joinubottom .joinu:first-child {margin-top: 47px;}
.joinubottom .joinu li {color: #000;}
.joinubottom .joinu li:first-child {width: 180px;font-weight: bold;}
.joinubottom .joinu li:first-child::after {content: none;}
.boardbtn {display: flex;justify-content: center;align-items: center;}
.boardbtn a {background: linear-gradient( to bottom, #14257a, #177bba);border-radius: 10px;width: 193px;height: 48px;padding: 0;border: 0;color: #fff;font-weight: bold;display: flex;align-items: center;justify-content: center;}
</style>
<style scoped src="@/styles/common.css"></style>
<style scoped src="@/styles/subcommon.css"></style>
